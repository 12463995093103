import { createReducer } from "@reduxjs/toolkit";

const initialState = {
  categories: [],
  loading: false,
  error: null,
};

export const categoriesReducer = (state = initialState, action) => {
  switch (action.type) {
    case "getAllCategoriesRequest":
      return { ...state, loading: true };
    case "getAllCategoriesSuccess":
      return { ...state, loading: false, categories: action.payload };
    case "getAllCategoriesFailed":
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};
