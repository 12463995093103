import { Button } from "@material-ui/core";
import { DataGrid } from "@material-ui/data-grid";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Loader from "../Layout/Loader";
import { getAllOrdersOfShop } from "../../redux/actions/order";
import { AiOutlineArrowRight } from "react-icons/ai";
import { useState } from "react";
import Meta from "../Meta";

const AllOrders = () => {
  const { orders, isLoading } = useSelector((state) => state.order);
  const { seller } = useSelector((state) => state.seller);
  const [searchQuery, setSearchQuery] = useState("");

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAllOrdersOfShop(seller._id));
  }, [dispatch]);

  const columns = [
    { field: "itemNo", headerName: "Order ID", minWidth: 150, flex: 0.7 },

    {
      field: "status",
      headerName: "Status",
      minWidth: 130,
      flex: 0.7,
      cellClassName: (params) => {
        return params.getValue(params.id, "status") === "Delivered"
          ? "greenColor"
          : "redColor";
      },
    },
    {
      field: "itemsQty",
      headerName: "Items Qty",
      type: "number",
      minWidth: 130,
      flex: 0.7,
    },

    {
      field: "total",
      headerName: "Total",
      type: "number",
      minWidth: 130,
      flex: 0.8,
    },

    {
      field: " ",
      flex: 1,
      minWidth: 150,
      headerName: "",
      type: "number",
      sortable: false,
      renderCell: (params) => {
        return (
          <>
            <Link to={`/order/${params.id}`}>
              <Button>
                <AiOutlineArrowRight size={20} />
              </Button>
            </Link>
          </>
        );
      },
    },
  ];

  const rows = orders
    ? orders.map((item) => ({
        itemNo: item.orderNo,
        id: item._id,
        itemsQty: item.cart.length,
        total: "Ksh " + item.totalPrice,
        status: item.status,
      }))
    : [];

  const filteredRows = searchQuery
    ? rows.filter((row) =>
        row.itemNo.toLowerCase().includes(searchQuery.toLowerCase())
      )
    : rows;

  return (
    <div className="relative w-full">
      <Meta title="Shop Dashboard Orders" />

      <div className="flex-1 relative">
        {/* Search Box */}
        <div className="w-full flex justify-end p-2">
          <input
            type="text"
            placeholder="Search Orders..."
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            className="border border-gray-300 rounded px-4 py-2 w-[250px] shadow-md"
          />
        </div>
        {/* DataGrid */}
        <div className="w-full min-h-[45vh] pt-2 rounded flex justify-center">
          <div className="w-[97%] flex justify-center">
            <DataGrid
              rows={filteredRows} // Use the filtered rows
              columns={columns}
              pageSize={10}
              disableSelectionOnClick
              autoHeight
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AllOrders;

// {
/* <>
      {isLoading ? (
        <Loader />
      ) : (

        <div className="w-full mx-8 pt-1 mt-10 bg-white">
          <DataGrid
            rows={row}
            columns={columns}
            pageSize={10}
            disableSelectionOnClick
            autoHeight
          />
        </div>
      )}
    </> */
// }
