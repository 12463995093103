import axios from "axios";
import React, { useEffect, useState } from "react";
import { server } from "../../server";
import { useLocation, useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { loadUser } from "../../redux/actions/user";

const PhoneLoginModal = ({ show, onClose }) => {
  const [phone, setPhone] = useState("");
  const [code, setCode] = useState("");
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState(null);
  const [display, setDisplay] = useState(false);
  const { isAuthenticated } = useSelector((state) => state.user);
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  const [isSending, setIsSending] = useState(false);
  const [timer, setTimer] = useState(60);

  useEffect(() => {
    if (isAuthenticated) {
      navigate("/");
    }
  }, [isAuthenticated, navigate]);

  const handleSignUp = () => {
    navigate("/sign-up");
  };

  const handlePhoneChange = (e) => {
    const value = e.target.value;
    if (/^\d{0,10}$/.test(value)) {
      setPhone(value);
    }
  };

  const handleCodeChange = (e) => {
    const value = e.target.value;
    if (/^[A-Z0-9]{0,4}$/.test(value)) {
      setCode(value);
    }
  };

  const handleSendCode = async () => {
    if (isSending) return;

    setIsSending(true);
    setTimer(60);

    try {
      if (phone.length < 9 || isNaN(phone)) {
        toast.error("Please enter a valid 10-digit phone number.");
        return;
      }
      const response = await axios.post(`${server}/user/create-otp`, {
        phoneNumber: phone,
      });

      if (response.data.success) {
        toast.success("OTP sent successfully! Check your WhatsApp and email.");
        setDisplay(true);
        startTimer();
      }
    } catch (error) {
      toast.error(
        error.response?.data?.message ||
          "Something went wrong. Please try again."
      );
      setIsSending(false);
    }
  };

  const startTimer = () => {
    const interval = setInterval(() => {
      setTimer((prev) => {
        if (prev === 1) {
          clearInterval(interval);
          setIsSending(false);
          return 0;
        }
        return prev - 1;
      });
    }, 1000);
  };

  const handleLogin = async () => {
    try {
      // Validate phone number
      if (phone.length < 9 || isNaN(phone)) {
        toast.error("Please enter a valid 10-digit phone number.");
        return;
      }

      // Validate OTP code
      if (code.length !== 4 || !/^[A-Z0-9]+$/.test(code)) {
        toast.error(
          "Please enter a valid 4-character OTP (letters and digits)."
        );
        return;
      }

      setLoading(true);

      // API call to login
      const response = await axios.post(
        `${server}/user/login-otp`,
        {
          phoneNumber: phone,
          otp: code,
        },
        { withCredentials: true }
      );

      if (response.data.success) {
        toast.success("Login successful!");

        // Load user data
        dispatch(loadUser());

        // Clear state
        setCode("");
        setPhone("");

        // Navigate to the previous URL or home page
        const redirectUrl = location?.state?.previousUrl || "/";
        navigate(redirectUrl);
      } else {
        toast.error("Invalid OTP. Please try again.");
      }
    } catch (error) {
      toast.error(
        error.response?.data?.message ||
          "Something went wrong. Please try again."
      );
    } finally {
      setLoading(false);
    }
  };

  if (!show) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-transparent bg-opacity-50 z-50">
      <div className="bg-white shadow-lg rounded-lg p-6 w-full max-w-xs sm:max-w-sm md:max-w-md relative mx-4">
        <button
          className="absolute top-3 right-3 text-gray-500 hover:text-gray-700"
          onClick={onClose}
        >
          ✕
        </button>
        <h2 className="text-center text-xl font-bold mb-4">Log in</h2>
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2">
            Phone
          </label>
          <div className="flex">
            <select
              className="border rounded-l-md px-2 py-2 text-sm sm:px-4 sm:py-3 sm:text-base focus:outline-none"
              defaultValue="KE +254"
            >
              <option value="KE +254">KE +254</option>
            </select>
            <input
              type="text"
              placeholder="Phone number"
              value={phone}
              onChange={handlePhoneChange}
              className="border rounded-r-md flex-grow px-2 py-2 text-sm sm:px-4 sm:py-3 sm:text-base focus:outline-none"
            />
          </div>
        </div>
        {display && isSending ? (
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2">
              Enter 4-digit code
            </label>
            <input
              type="text"
              placeholder="Code"
              value={code}
              onChange={handleCodeChange}
              className="border rounded-md w-full px-2 py-2 text-sm sm:px-4 sm:py-3 sm:text-base focus:outline-none"
            />
          </div>
        ) : null}

        <button
          onClick={handleSendCode}
          className="w-full bg-blue-500 text-white py-1 text-xs sm:py-1.5 sm:text-sm rounded-md hover:bg-blue-600 mb-1"
          disabled={isSending}
        >
          {isSending ? `Resend  ${timer}s` : "Send code"}
        </button>
        {display && isSending ? (
          <button
            onClick={handleLogin}
            className="w-full bg-green-500 text-white py-1 text-xs sm:py-1.5 sm:text-sm rounded-md hover:bg-green-600"
          >
            Log in
          </button>
        ) : null}

        <div className="text-center text-sm text-gray-500 mt-4">
          Don’t have an account?{" "}
          <button
            onClick={handleSignUp}
            className="text-blue-500 hover:underline"
          >
            Sign up
          </button>
        </div>
      </div>
    </div>
  );
};

export default PhoneLoginModal;
