// import { useEffect, useState } from "react";
// import { CiMail } from "react-icons/ci";
// import { FaRegUser } from "react-icons/fa";
// import { IoPhonePortraitOutline } from "react-icons/io5";
// import { useSelector } from "react-redux";
// import { Link, Navigate, useLocation } from "react-router-dom";
// import { useNavigate } from "react-router-dom";

// const ProtectedRoute = ({ children }) => {
//   const navigate = useNavigate();
//   const location = useLocation();
//   const [showLogin, setShowLogin] = useState(false);
//   const { loading, isAuthenticated } = useSelector((state) => state.user);
//   useEffect(() => {
//     if (loading === false) {
//       if (!isAuthenticated) {
//         // navigate("/login", {
//         //   state: {
//         //     previousUrl: location.pathname,
//         //   },
//         // });
//         // return <Navigate to="/login" />;
//         setShowLogin(true);
//       }
//     }
//   }, [isAuthenticated, location.pathname, loading, navigate]);

//   // if (loading === false) {
//   //   return children;
//   // }
//   const handleOrderAsGuest = () => {
//     navigate("/guest-checkout");
//   };
//   return (
//     <>
//       {showLogin && (
//         <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
//           <div className="bg-white shadow-lg rounded-lg p-6 w-96 relative">
//             <button
//               className="absolute top-3 right-3 text-gray-500 hover:text-gray-700"
//               onClick={() => setShowLogin(false)} // Close the modal
//             >
//               ✕
//             </button>
//             <h2 className="text-center text-xl font-bold mb-4">
//               Log in to your account
//             </h2>
//             <div className="space-y-3">
//               <button className="w-full h-10 border rounded-md text-gray-700 hover:bg-gray-100 flex items-center justify-center mt-10">
//                 <IoPhonePortraitOutline className="mr-2 text-xl" />
//                 Login with phone
//               </button>
//               <button className="w-full h-10 border rounded-md text-gray-700 hover:bg-gray-100 flex items-center justify-center">
//                 <CiMail className="mr-2 text-xl" />
//                 Login with email
//               </button>
//               <button
//                 onClick={handleOrderAsGuest}
//                 className="w-full h-10 border rounded-md text-gray-700 flex items-center justify-center hover:bg-gray-100"
//               >
//                 <FaRegUser className="mr-2 text-xl" />
//                 Continue as guest
//               </button>
//             </div>
//             <div className="text-center text-sm text-gray-500 mt-6">
//               By continuing, you agree to Ninetyone{" "}
//               <Link to="/terms" className="text-blue-500 hover:underline">
//                 Terms of Service
//               </Link>{" "}
//               and{" "}
//               <Link to="/privacy" className="text-blue-500 hover:underline">
//                 Privacy Policy
//               </Link>
//               .
//             </div>
//             <div className="text-center text-sm text-gray-500 mt-4">
//               Don’t have an account?{" "}
//               <Link to="/sign-up" className="text-blue-500 hover:underline">
//                 Sign up
//               </Link>
//             </div>
//           </div>
//         </div>
//       )}
//     </>
//   );
// };

// export default ProtectedRoute;

import { useEffect, useState } from "react";
import { CiMail } from "react-icons/ci";
import { FaRegUser } from "react-icons/fa";
import { IoPhonePortraitOutline } from "react-icons/io5";
import { useSelector } from "react-redux";
import { Link, Navigate, useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import PhoneLoginModal from "../components/Login/phoneLogin";

const ProtectedRoute = ({ children }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [showLogin, setShowLogin] = useState(false);
  const { loading, isAuthenticated } = useSelector((state) => state.user);
  const [showLoginPhone, setShowLoginPhone] = useState(false);

  // Effect to handle authentication check
  useEffect(() => {
    if (loading === false && !isAuthenticated) {
      setShowLogin(true);
    }
  }, [isAuthenticated, loading]);

  // Handle guest checkout
  const handleOrderAsGuest = () => {
    navigate("/guest-checkout");
  };
  const handleLoginEmail = () => {
    setShowLogin(false);
    navigate("/login");
  };

  // If still loading, don't show protected content or modal
  if (loading) return null;

  // If authenticated, render children (protected content)
  if (isAuthenticated) return children;

  return (
    <>
      {showLogin && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
          <div className="bg-white shadow-lg rounded-lg p-6 w-96 relative">
            <button
              className="absolute top-3 right-3 text-gray-500 hover:text-gray-700"
              onClick={() => setShowLogin(false)} // Close the modal
            >
              ✕
            </button>
            <h2 className="text-center text-xl font-bold mb-4">
              Log in to your account
            </h2>
            <div className="space-y-3">
              <button
                onClick={() => setShowLoginPhone(true)}
                className="w-full h-10 border rounded-md text-gray-700 hover:bg-gray-100 flex items-center justify-center mt-10"
              >
                <IoPhonePortraitOutline className="mr-2 text-xl" />
                Log in with Phone
              </button>
              <PhoneLoginModal
                show={showLoginPhone}
                onClose={() => setShowLoginPhone(false)}
              />
              <button
                onClick={() => handleLoginEmail()}
                className="w-full h-10 border rounded-md text-gray-700 hover:bg-gray-100 flex items-center justify-center"
              >
                <CiMail className="mr-2 text-xl" />
                Login with email
              </button>
              <button
                onClick={() => handleOrderAsGuest()}
                className="w-full h-10 border rounded-md text-gray-700 flex items-center justify-center hover:bg-gray-100"
              >
                <FaRegUser className="mr-2 text-xl" />
                Continue as guest
              </button>
            </div>
            <div className="text-center text-sm text-gray-500 mt-6">
              By continuing, you agree to Ninetyone{" "}
              <Link to="/terms" className="text-blue-500 hover:underline">
                Terms of Service
              </Link>{" "}
              and{" "}
              <Link to="/privacy" className="text-blue-500 hover:underline">
                Privacy Policy
              </Link>
              .
            </div>
            <div className="text-center text-sm text-gray-500 mt-4">
              Don’t have an account?{" "}
              <Link to="/sign-up" className="text-blue-500 hover:underline">
                Sign up
              </Link>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ProtectedRoute;
