import { Button } from "@material-ui/core";
import { DataGrid } from "@material-ui/data-grid";
import React, { useEffect } from "react";
import { AiOutlineDelete, AiOutlineEye, AiOutlineEdit } from "react-icons/ai";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { deleteProduct } from "../../redux/actions/product";
import axios from "axios";
import { server } from "../../server";
import { useState } from "react";
import { toast } from "react-hot-toast";
import CustomModal from "../CustomModal";

const AllProducts = ({ searchQuery }) => {
  const [data, setData] = useState([]);
  const dispatch = useDispatch();

  const navigate = useNavigate();
  const [filteredData, setFilteredData] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalOpen2, setModalOpen2] = useState(false);
  const [id, setId] = useState("");

  const [allProducts, setAllProducts] = useState([]);

  useEffect(() => {
    // Fetch all products from the server
    axios
      .get(`${server}/product/admin-all-products`, { withCredentials: true })
      .then((res) => {
        setAllProducts(res.data.products);
        setFilteredData(res.data.products); // Initially display all products
      })
      .catch((err) => {
        console.error(err);
        toast.error("Failed to fetch products.");
      });
  }, []);

  useEffect(() => {
    // Filter products based on the search query
    if (searchQuery) {
      const filteredProducts = allProducts.filter((product) => {
        const isItemNoSearch = /\d/.test(searchQuery); // Check if search query contains numbers
        if (isItemNoSearch) {
          return (
            product.itemNo &&
            product.itemNo.toLowerCase().startsWith(searchQuery.toLowerCase())
          );
        } else {
          return product.name.toLowerCase().includes(searchQuery.toLowerCase());
        }
      });
      setFilteredData(filteredProducts);
    } else {
      setFilteredData(allProducts); // Reset to all products if search query is empty
    }
  }, [searchQuery, allProducts]);

  const handleDelete = async () => {
    dispatch(deleteProduct(id));
    toast.success("Product deleted!");
    navigate("/admin/dashboard");
  };

  const setOperations = async (productId) => {
    setModalOpen(true);
    setId(productId);
  };
  const handleDeleteOutOfStock = async () => {
    try {
      const response = await axios.delete(
        `${server}/product/delete-out-of-stock-products`
      );
      if (response.data.success) {
        toast.success(response.data.message); // Show success message
      }
    } catch (error) {
      console.error("Error deleting out-of-stock products:", error);
      alert("Failed to delete out-of-stock products.");
    }
    setModalOpen2(false);
  };

  const columns = [
    { field: "id", headerName: "ItemNo", minWidth: 150, flex: 0.7 },
    {
      field: "name",
      headerName: "Name",
      minWidth: 180,
      flex: 1.4,
    },
    {
      field: "price",
      headerName: "Price",
      minWidth: 100,
      flex: 0.6,
    },
    {
      field: "Stock",
      headerName: "Stock",
      type: "number",
      minWidth: 80,
      flex: 0.5,
    },

    {
      field: "sold",
      headerName: "Sold out",
      type: "number",
      minWidth: 130,
      flex: 0.6,
    },
    {
      field: "Preview",
      flex: 0.8,
      minWidth: 100,
      headerName: "",
      type: "number",
      sortable: false,
      renderCell: (params) => {
        return (
          <>
            <Link to={`/product/${params.id}`}>
              <Button>
                <AiOutlineEye size={20} />
              </Button>
            </Link>
          </>
        );
      },
    },
    {
      field: "Edit",
      flex: 0.8,
      minWidth: 120,
      headerName: "",
      type: "number",
      sortable: false,
      renderCell: (params) => {
        const productId = params.row.id;
        return (
          <>
            <Link to={`/admin-edit-product/${productId}`}>
              <Button>
                <AiOutlineEdit size={20} />
              </Button>
            </Link>
          </>
        );
      },
    },

    {
      field: "Delete",
      flex: 0.8,
      minWidth: 120,
      headerName: "",
      type: "number",
      sortable: false,
      renderCell: (params) => {
        return (
          <>
            <Button onClick={() => setOperations(params.row.id)}>
              <AiOutlineDelete size={20} />
            </Button>
          </>
        );
      },
    },
  ];

  // Prepare rows for DataGrid
  const rows = filteredData.map((item) => ({
    id: item._id,
    name: item.name,
    price: "Ksh " + item.discountPrice,
    Stock: item.stock,
    sold: item.sold_out,
  }));

  return (
    <>
      <div className="w-full mx-8 pt-1 mt-10 bg-white">
        {modalOpen && (
          <CustomModal
            message={"Are you sure you want to delete this product?"}
            ok={" Yes, I'm sure"}
            cancel={"No, cancel"}
            setModalOpen={setModalOpen}
            performAction={() => handleDelete()}
            closeModel={() => setModalOpen(false)}
          />
        )}
        {modalOpen2 && (
          <CustomModal
            message={
              "Are you sure you want to delete all out-of-stock products?"
            }
            ok={"Yes, delete them"}
            cancel={"No, keep them"}
            setModalOpen={setModalOpen2}
            performAction={handleDeleteOutOfStock}
            closeModel={() => setModalOpen2(false)}
          />
        )}

        {/* Delete Out-of-Stock Products Button */}
        <button
          className="mb-4 px-4 py-2 bg-red-500 text-white rounded hover:bg-red-700"
          onClick={() => setModalOpen2(true)}
        >
          Delete All Out-of-Stock Products
        </button>

        <DataGrid
          rows={rows}
          columns={columns}
          pageSize={10}
          disableSelectionOnClick
          autoHeight
        />
      </div>
    </>
  );
};

export default AllProducts;
