import axios from "axios";
import { server } from "../../server";

export const getAllCarousel = () => async (dispatch) => {
  try {
    dispatch({ type: "getAllCarouselRequest" });

    const { data } = await axios.get(`${server}/carousel/get-carousels`);

    if (!data || !data.carousel) {
      throw new Error(" data is missing");
    }

    dispatch({
      type: "getAllCarouselSuccess",
      payload: data.carousel,
    });
  } catch (error) {
    const errorMessage =
      error?.response?.data?.message || error.message || "An error occurred";

    dispatch({
      type: "getAllCarouselFailed",
      payload: errorMessage,
    });
  }
};
