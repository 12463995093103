import axios from "axios";
import { server } from "../../server";

export const getAllCategories = () => async (dispatch) => {
  try {
    dispatch({ type: "getAllCategoriesRequest" });

    const { data } = await axios.get(`${server}/category/category`);

    if (!data || !data.categories) {
      throw new Error("Categories data is missing");
    }

    dispatch({
      type: "getAllCategoriesSuccess",
      payload: data.categories,
    });
  } catch (error) {
    const errorMessage =
      error?.response?.data?.message || error.message || "An error occurred";

    dispatch({
      type: "getAllCategoriesFailed",
      payload: errorMessage,
    });
  }
};
