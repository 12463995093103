// import React, { useEffect, useState } from "react";
// import { DataGrid } from "@material-ui/data-grid";
// import axios from "axios";
// import { server } from "../../server";
// import { toast } from "react-hot-toast";

// const AllTransactions = () => {
//   const [transactions, setTransactions] = useState([]);
//   const [filteredTransactions, setFilteredTransactions] = useState([]);
//   const [searchQuery, setSearchQuery] = useState("");

//   useEffect(() => {
//     // Fetch transactions from the server
//     axios
//       .get(`${server}/tiny/get-transactions`, { withCredentials: true })
//       .then((res) => {
//         setTransactions(res.data);
//         setFilteredTransactions(res.data); // Initially display all transactions
//       })
//       .catch((err) => {
//         console.error(err);
//         toast.error("Failed to fetch transactions.");
//       });
//   }, []);

//   // Handle search input change
//   const handleSearch = (e) => {
//     const query = e.target.value.toLowerCase();
//     setSearchQuery(query);
//     if (query) {
//       const filtered = transactions.filter((transaction) =>
//         Object.values(transaction).some((value) =>
//           value.toString().toLowerCase().includes(query)
//         )
//       );
//       setFilteredTransactions(filtered);
//     } else {
//       setFilteredTransactions(transactions); // Reset to all transactions if search query is empty
//     }
//   };

//   const columns = [
//     {
//       field: "id",
//       headerName: "Mpesa Ref",
//       minWidth: 250,
//       flex: 0.5,
//       headerAlign: "center",
//       align: "center",
//     },
//     {
//       field: "customer_number",
//       headerName: "Customer Number",
//       minWidth: 180,
//       flex: 1,
//       headerAlign: "center",
//       align: "center",
//     },
//     {
//       field: "amount",
//       headerName: "Amount",
//       minWidth: 120,
//       flex: 0.7,
//       headerAlign: "center",
//       align: "center",
//     },
//     {
//       field: "createdAt",
//       headerName: "Date",
//       minWidth: 150,
//       flex: 1,
//       headerAlign: "center",
//       align: "center",
//     },
//   ];

//   const rows = filteredTransactions.map((transaction) => ({
//     id: transaction.mpesa_ref,
//     customer_number: transaction.customer_number,
//     amount: transaction.amount,
//     createdAt: new Date(transaction.createdAt).toLocaleString(),
//   }));

//   return (
//     <div className="w-full mx-8 pt-1 mt-10 bg-white">
//       {/* Search Box */}
//       <div className="flex justify-end mb-4">
//         <input
//           type="text"
//           className="p-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
//           placeholder="Search Transactions..."
//           value={searchQuery}
//           onChange={handleSearch}
//         />
//       </div>

//       {/* DataGrid */}
//       <DataGrid
//         rows={rows}
//         columns={columns}
//         pageSize={10}
//         disableSelectionOnClick
//         autoHeight
//       />
//     </div>
//   );
// };

// export default AllTransactions;
import React, { useEffect, useState } from "react";
import { DataGrid } from "@material-ui/data-grid";
import axios from "axios";
import { server } from "../../server";
import { toast } from "react-hot-toast";

const AllTransactions = () => {
  const [transactions, setTransactions] = useState([]);
  const [filteredTransactions, setFilteredTransactions] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [filterType, setFilterType] = useState("all"); // Filter state (all, withdraws, deposits)

  useEffect(() => {
    // Fetch transactions from the server
    axios
      .get(`${server}/tiny/get-transactions`, { withCredentials: true })
      .then((res) => {
        setTransactions(res.data);
        setFilteredTransactions(res.data); // Initially display all transactions
      })
      .catch((err) => {
        console.error(err);
        toast.error("Failed to fetch transactions.");
      });
  }, []);

  // Handle search input change
  const handleSearch = (e) => {
    const query = e.target.value.toLowerCase();
    setSearchQuery(query);
    filterTransactions(query, filterType);
  };

  // Filter transactions by search query and type
  const filterTransactions = (query, type) => {
    let filtered = transactions;

    // Apply type filter
    if (type === "withdraws") {
      filtered = transactions.filter(
        (transaction) => transaction.type === "withdraw"
      );
    } else if (type === "deposits") {
      filtered = transactions.filter(
        (transaction) => transaction.type === "deposit"
      );
    }

    // Apply search filter
    if (query) {
      filtered = filtered.filter((transaction) =>
        Object.values(transaction).some((value) =>
          value.toString().toLowerCase().includes(query)
        )
      );
    }

    setFilteredTransactions(filtered);
  };

  // Handle filter button click
  const handleFilterClick = (type) => {
    setFilterType(type);
    filterTransactions(searchQuery, type);
  };

  const columns = [
    {
      field: "id",
      headerName: "Mpesa Ref",
      minWidth: 250,
      flex: 0.5,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "customer_number",
      headerName: "Customer Number",
      minWidth: 180,
      flex: 1,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "amount",
      headerName: "Amount",
      minWidth: 120,
      flex: 0.7,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "createdAt",
      headerName: "Date",
      minWidth: 150,
      flex: 1,
      headerAlign: "center",
      align: "center",
    },
  ];

  const rows = filteredTransactions.map((transaction) => ({
    id: transaction.mpesa_ref,
    customer_number: transaction.customer_number,
    amount: transaction.amount,
    createdAt: new Date(transaction.createdAt).toLocaleString(),
  }));

  return (
    <>
      <div className="w-full mx-8 pt-1 mt-10 bg-white">
        {/* Filter Buttons */}
        <div className="flex justify-start mb-4 space-x-2">
          <button
            className={`px-4 py-2 text-sm border rounded-md ${
              filterType === "all"
                ? "bg-blue-500 text-white"
                : "bg-white text-blue-500 border-blue-500"
            }`}
            onClick={() => handleFilterClick("all")}
          >
            All
          </button>
          <button
            className={`px-4 py-2 text-sm border rounded-md ${
              filterType === "withdraws"
                ? "bg-blue-500 text-white"
                : "bg-white text-blue-500 border-blue-500"
            }`}
            onClick={() => handleFilterClick("withdraws")}
          >
            OUT
          </button>
          <button
            className={`px-4 py-2 text-sm border rounded-md ${
              filterType === "deposits"
                ? "bg-blue-500 text-white"
                : "bg-white text-blue-500 border-blue-500"
            }`}
            onClick={() => handleFilterClick("deposits")}
          >
            IN
          </button>
        </div>

        {/* Search Box */}
        <div className="flex justify-end mb-4">
          <input
            type="text"
            className="p-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
            placeholder="Search Transactions..."
            value={searchQuery}
            onChange={handleSearch}
          />
        </div>

        {/* DataGrid */}
        <DataGrid
          rows={rows}
          columns={columns}
          pageSize={10}
          disableSelectionOnClick
          autoHeight
        />
      </div>
    </>
  );
};

export default AllTransactions;
